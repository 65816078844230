import { ChangeEvent, useState } from "react";
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { IoArrowUndoOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { enquiryTicketRequest } from "../../../../../../models/request/enquiry-request";
import {
  useGetEnquiryTicketByEnquiryIdQuery,
  useReplyEnquiryTicketMutation,
} from "../../../../../../services/enquiry-service";
import { useGetUserByIdQuery } from "../../../../../../services/user-service";

const ViewEnquiriesModal = ({ enquiryData, show, onClose }) => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);

  const [ticketData, setTicketData] = useState<enquiryTicketRequest>({
    enquiryId: enquiryData?.id,
    userSupportId: userId,
    replyMessage: "",
    replyChannel: "WEB",
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTicketData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [replyEnquiryTicket] = useReplyEnquiryTicketMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await replyEnquiryTicket(ticketData);
      const { code, message, data } = response["data"];
      if (code === 200) {
        toast.success("Response successfully sent");
        navigate("/enquiries/reply-sent", { state: { data } });
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  const enquiryId = enquiryData?.id;

  const { data: response, isLoading: enquiryTicketLoading } =
    useGetEnquiryTicketByEnquiryIdQuery(enquiryId);
  const replyEnquiry = response?.data?.replyMessage;

  const { data: userResponse, isLoading: userLoading } = useGetUserByIdQuery(
    enquiryData?.repliedBy
  );
  const profile = userResponse?.data;

  if (enquiryTicketLoading || userLoading) return <Spinner />;

  return (
    <>
      <Modal className="border-0" show={show} onHide={onClose}>
        <Modal.Header className="border-0 mx-4 my-2" closeButton>
          <Modal.Title className="fs-6">
            <img
              src={`https://ui-avatars.com/api/?name=${enquiryData?.name}&background=random&size=32`}
              className="rounded-circle me-2"
              alt="avatar"
              style={{ width: "40px", height: "40px" }}
            />
            <span className="my-auto">{enquiryData?.name}</span>
          </Modal.Title>
        </Modal.Header>
        <Row className="m-4">
          <p className="fs-6 py-1 fw-normal">{enquiryData?.message}</p>
          <p className="fs-6 py-1 fw-normal fst-italic text-end">
            {replyEnquiry}
          </p>
          {enquiryData?.enquiryClosed === true && (
            <p
              className="  fw-light fst-italic text-end"
              style={{ fontSize: "12px" }}
            >
              replied by {profile?.firstName} {profile?.lastName}
            </p>
          )}
        </Row>

        {enquiryData?.enquiryClosed === false && (
          <>
            <Modal.Body className="mx-4 my-0 py-0">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    placeholder="Enter reply"
                    name="replyMessage"
                    onChange={handleFormChanged}
                    as="textarea"
                    rows={3}

                    // disabled={enquiryData?.enquiryClosed === true}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <Button
                onClick={handleFormSubmit}
                variant="dark"
                className="rounded-5"
                style={{ fontSize: "14px" }}
                disabled={!ticketData.replyMessage}
              >
                Reply <IoArrowUndoOutline />
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewEnquiriesModal;
