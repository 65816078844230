import React from "react";
import { useParams } from "react-router-dom";
import { useGetGroupEventsByIdQuery } from "../../../../../services/mentoring-service";
import PageLoader from "../../../../components/PageLoader";
import MentorshipEventHeader from "./components/MentorshipEventHeader";
import MentorshipEventBody from "./components/MentorshipEventBody";

const ViewMentorshipEvents = () => {
  const { eventId, groupTitle, groupId } = useParams();
  const eventID = Number(eventId);

  const { data: response, isLoading } = useGetGroupEventsByIdQuery(eventID);
  const groupEventData = response?.data;

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <div>
      <MentorshipEventHeader
        groupEventData={groupEventData}
        groupTitle={groupTitle}
        groupId={groupId}
      />
      <MentorshipEventBody groupEventData={groupEventData} />
    </div>
  );
};

export default ViewMentorshipEvents;
