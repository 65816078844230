import React, { useState } from "react";
import { Breadcrumb, Button, Navbar } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { IoArrowBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { RxHamburgerMenu } from "react-icons/rx";
import MentoringCheckIn from "../../groupDetails/components/groupEvents/MentoringCheckIn";

const MentorshipEventHeader = ({ groupEventData, groupTitle, groupId }) => {
  const roles = sessionStorage.getItem("roles");
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const allowedRoles = ["Admin", "Super Admin", "Call Center Manager"];
  const hasAllowedRole =
    roles && allowedRoles.some((role) => roles.includes(role));

  return (
    <div>
      <Navbar expand="lg" className="p-4 bg-black">
        <div className="d-flex flex-column">
          <Navbar.Brand className="text-white py-3 create-event-title">
            {groupEventData?.title}
          </Navbar.Brand>
          <Breadcrumb>
            <Breadcrumb.Item
              className=" text-white text-decoration-none"
              as={Link}
              to={`/e-mentoring/group-details/${groupId}`}
              style={{ fontSize: "12px" }}
              active
            >
              {groupTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{ fontSize: "12px" }}
              active
              className="text-white"
            >
              {groupEventData?.title}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-white fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <div className="d-flex ms-auto flex-wrap justify-content-start">
            {hasAllowedRole ? (
              <>
                <Link
                  to={`/e-mentoring/edit-group-events/${groupTitle}/${groupId}/${groupEventData?.id}`}
                >
                  <Button className="ms-2" variant="dark rounded-5">
                    Edit event <CiEdit />
                  </Button>
                </Link>
              </>
            ) : (
              <div></div>
              // <MentoringCheckIn eventId={groupEventData.id} />
            )}
            <Link to={`/e-mentoring/group-details/${groupId}`}>
              <Button
                className="ms-2"
                variant="dark rounded-5"
                style={{ fontSize: "14px", fontWeight: "400" }}
              >
                Go back to your group <IoArrowBackOutline />
              </Button>
            </Link>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default MentorshipEventHeader;
