import React from "react";
import { Row, Image } from "react-bootstrap";

const Announcement = ({ groupAnnouncement }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return { date: formattedDate, time: formattedTime };
  };

  if (!groupAnnouncement || groupAnnouncement.length === 0) {
    return <h5 style={{ fontSize: "14px" }}>No Announcement</h5>;
  }
  return (
    <div>
      {groupAnnouncement.map((announcement) => (
        <Row className="pt-4" key={announcement.id}>
          <div className="d-flex w-100 rounded-5 align-items-center">
            <Image
              src={`https://ui-avatars.com/api/?name=${announcement.announcerName}&background=random&size=32`}
              alt="Avatar"
              roundedCircle
              className="rounded-circle my-auto"
              width="40px"
              height="40px"
            />
            <div className="ms-2">
              <p
                className="p-0 m-0"
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                {announcement.announcerName}
                <span
                  style={{ fontSize: "10px", fontWeight: "500" }}
                  className="text-muted mx-1"
                >
                  {formatDate(announcement.createdOn).date}
                </span>
                <span
                  style={{ fontSize: "10px", fontWeight: "500" }}
                  className="text-muted mx-1"
                >
                  {formatDate(announcement.createdOn).time}
                </span>
              </p>
              <p className="lh-small m-0 p-0 fs-6">{announcement.subject}</p>
            </div>
          </div>
        </Row>
      ))}
    </div>
  );
};

export default Announcement;
