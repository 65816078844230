import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-date-range";
import { editGroupEvent } from "../../../../../../models/request/mentoring-request";
import {
  useEditGroupEventsMutation,
  useGetGroupEventsByIdQuery,
} from "../../../../../../services/mentoring-service";
import PageLoader from "../../../../../components/PageLoader";
import FileUpload from "../../../events/editEvent/components/FileUpload";

const EditMentorshipEventsBody = ({ groupId, eventId, groupTitle }) => {
  const navigate = useNavigate();
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const userId = parseInt(storedUserId);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const { data: response, isLoading } = useGetGroupEventsByIdQuery(eventId);
  const groupEventData = response?.data;

  const [formData, setFormData] = useState<editGroupEvent>({
    id: eventId,
    modifiedBy: userId,
    title: groupEventData?.title,
    description: groupEventData?.description,
    link: groupEventData?.link,
    image: groupEventData?.image,
    groupId: groupId,
    startDay: groupEventData?.startDay,
    endDay: groupEventData?.endDay,
    startTime: groupEventData?.startTime,
    endTime: groupEventData?.endTime,
  });

  const handleFormChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDateChange = (item) => {
    const startDate = item.selection.startDate.toISOString().split("T")[0];
    const endDate = item.selection.endDate
      ? item.selection.endDate.toISOString().split("T")[0]
      : null;
    setFormData((prevEventData) => ({
      ...prevEventData,
      startDay: startDate,
      endDay: endDate,
    }));
    setDate([item.selection]);

    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
  };

  const handleImageChanged = (imageInfo: {
    target: { name: string; value: string };
  }) => {
    console.log(
      "Image changed:",
      imageInfo.target.name,
      imageInfo.target.value
    );
    setFormData((prevEventData) => ({
      ...prevEventData,
      [imageInfo.target.name]: imageInfo.target.value,
    }));
  };

  const [editGroupEvent] = useEditGroupEventsMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await editGroupEvent(formData);
      const { code, message } = response["data"];
      if (code === 0) {
        navigate(
          `/e-mentoring/view-group-events/${groupId}/${groupTitle}/${eventId}`
        );
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      id: eventId,
      modifiedBy: userId,
      title: groupEventData?.title,
      description: groupEventData?.description,
      link: groupEventData?.link,
      image: groupEventData?.image,
      groupId: groupId,
      startDay: groupEventData?.startDay,
      endDay: groupEventData?.endDay,
      startTime: groupEventData?.startTime,
      endTime: groupEventData?.endTime,
    }));
  }, [eventId, groupEventData, groupId, userId]);

  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }

  return (
    <div>
      <Container>
        <Row className="p-4 align-items-center">
          <h5 className="">Group Event Details</h5>
          <hr className="hr" />
        </Row>
        <Row>
          <Form>
            <Row className="px-4">
              <Col lg={8}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Event Title</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter Event Title"
                        name="title"
                        value={formData.title}
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Event Link</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter Group Link"
                        name="link"
                        value={formData.link}
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Group Description</Form.Label>
                  <Form.Control
                    value={formData.description}
                    onChange={handleFormChanged}
                    placeholder="Enter Group Description"
                    name="description"
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                        size="lg"
                        type="time"
                        placeholder="Enter Event Start Time"
                        name="startTime"
                        value={formData.startTime}
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>End Time</Form.Label>
                      <Form.Control
                        size="lg"
                        type="time"
                        placeholder="Enter Event End Time"
                        name="endTime"
                        value={formData.endTime}
                        onChange={handleFormChanged}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col className="bg-white ">
                    <p style={{ fontSize: "12px" }}>Start date - End date</p>
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      ranges={[
                        {
                          startDate: formData.startDay
                            ? new Date(formData.startDay)
                            : null,
                          endDate: formData.endDay
                            ? new Date(formData.endDay)
                            : null,
                          key: "selection",
                        },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <FileUpload
                    name="image"
                    onChange={handleImageChanged}
                    imageValue={formData.image}
                  />
                </Row>
              </Col>
            </Row>

            <div className="d-flex w-100 justify-content-end">
              <Button
                className="rounded-5 w-25 py-3"
                variant="dark"
                style={{ fontSize: "14px" }}
                onClick={handleFormSubmit}
                disabled={
                  !formData.title ||
                  !formData.link ||
                  !formData.startTime ||
                  !formData.endTime ||
                  !formData.groupId ||
                  !formData.startDay ||
                  !formData.endDay
                }
              >
                Save Changes
              </Button>
            </div>
          </Form>
        </Row>
      </Container>
    </div>
  );
};

export default EditMentorshipEventsBody;
