import React from "react";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { SiZoom, SiGooglemeet, SiClubhouse } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";

const iconMapping = {
  WHATSAPP: FaWhatsapp,
  TELEGRAM: FaTelegramPlane,
  ZOOM: SiZoom,
  GOOGLE_MEET: SiGooglemeet,
  CLUBHOUSE: SiClubhouse,
  TWITTER_SPACE: FaXTwitter,
};

const GroupChannels = ({ groupChannel }) => {

  const formatUrl = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  if (!groupChannel || groupChannel.length === 0) {
    return <h5 style={{ fontSize: "14px" }}>No Social Links</h5>;
  }

  return (
    <div className="mt-4 d-flex">
      {groupChannel.map((channel) => {
        const IconComponent = iconMapping[channel.platform];
        return (
          <a
            key={channel.id}
            href={formatUrl(channel.url)}
            target="_blank"
            rel="noopener noreferrer"
            className="me-3"
          >
            {IconComponent && <IconComponent className="fs-1 text-dark" />}
          </a>
        );
      })}
    </div>
  );
};

export default GroupChannels;
