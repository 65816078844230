import { ChangeEvent, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useEditGroupMutation, useGetGroupByIDQuery } from "../../../../../../services/mentoring-service";
import { editGroupType } from "../../../../../../models/request/mentoring-request";
import PageLoader from "../../../../../components/PageLoader";

const EditGroupBody = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const groupid = Number(id);
  const { data: response, isLoading } = useGetGroupByIDQuery(groupid);
  const groupData = response?.data;

  const [editgroup, setEditGroup] = useState<editGroupType>({
    id: groupData?.id,
    name: groupData?.name,
    description: groupData?.description,
    enabled: groupData?.enabled,
  });

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditGroup((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    console.log("Checked:", checked);
    setEditGroup((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const [editGroup] = useEditGroupMutation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await editGroup(editgroup);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate("/e-mentoring")
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setEditGroup((prev) => ({
      ...prev,
      id: groupData?.id,
      name: groupData?.name,
      description: groupData?.description,
      enabled: groupData?.enabled,
    }));
  }, [groupData]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <Container>
      <Row className="p-4 align-items-center">
        <h5 className="">Group Details</h5>
        <hr className="hr" />
      </Row>
      <Row className="px-4">
        <Col>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Enter Group Name"
                name="name"
                value={editgroup.name}
                disabled={editgroup.enabled === false}
                onChange={handleFormChanged}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Group Description</Form.Label>
              <Form.Control
                onChange={handleFormChanged}
                placeholder="Enter Group Description"
                name="description"
                as="textarea"
                value={editgroup.description}
                rows={3}
                disabled={editgroup.enabled === false}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Enable or Disable Group</Form.Label>
              <Form.Check
                type="switch"
                id="custom-switch"
                name="enabled"
                // label="Availability"
                checked={editgroup.enabled}
                onChange={handleFormCheck}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                className="rounded-5"
                variant="dark"
                onClick={handleFormSubmit}
                disabled={!editgroup.name || !editgroup.description}
              >
                Save Changes
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditGroupBody;
