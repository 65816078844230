import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDeleteUserByIdMutation } from "../../../../../../services/user-service";

const DeleteUser = ({ user, show, onClose }) => {
  console.log("usersDelete", user);
  const [deleteUserMutation] = useDeleteUserByIdMutation();

  const handleDeleteUser = async () => {
    try {
      // Call the delete user mutation with the user ID
      const response = await deleteUserMutation(user?.id);
      onClose(); // Close the modal after successful deletion
      const { code, message } = response["data"];
      // Handle the response
      console.log("Upload successful", response);
      if (code === 200) {
        toast.success(message);
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(error.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-6">Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete {user?.firstName} {user?.lastName}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            className="users-text rounded-5"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            className="users-text rounded-5"
            variant="danger"
            onClick={handleDeleteUser}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteUser;
