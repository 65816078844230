import { GridColDef } from "@mui/x-data-grid";
import { Button, Image } from "react-bootstrap";

const EnquiryTableComponent = (handleViewEnquiry) => {
  const EnquiriesTableElements: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      // flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "header--bg",
      flex: 1,
      renderCell: (params: any) => (
        <>
          <Image
            src={`https://ui-avatars.com/api/?name=${params?.row?.name}&background=random&size=32`}
            alt="Avatar"
            roundedCircle
            className="me-2"
          />
          {params?.row?.name || ""}
        </>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "header--bg",
    },
    {
      field: "phoneNumber",
      headerName: "phoneNumber",
      flex: 1,
      headerClassName: "header--bg",
    },

    {
      field: "enquiryNumber",
      headerName: "Enquiry Number",
      flex: 1,
      headerClassName: "header--bg",
    },

    {
      field: "enquiryClosed",
      headerName: "Enquiry status",
      flex: 1,
      headerClassName: "header--bg",
      renderCell: (params) => {
        const status = params.value ? "Closed" : "Open";
        const colorClass = params.value ? "text-success" : "text-danger";
        return <span className={colorClass}>{status}</span>;
      },
    },

    {
      field: "createdAT",
      headerName: "Date Created",
      headerClassName: "header--bg",
      flex: 1,
      valueFormatter: (params: any) =>
        new Date(params.value).toLocaleDateString(),
    },

    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "header--bg",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        console.log("params", params);
        return (
          <div>
            <Button
              variant="light"
              className="rounded-5 "
              style={{ fontSize: "12px", background: "rgba(25, 22, 29, 0.06)" }}
              onClick={() => handleViewEnquiry(params.row)}
            >
              View enquiries ...
            </Button>
          </div>
        );
      },
    },
  ];
  return EnquiriesTableElements;
};
export default EnquiryTableComponent;
