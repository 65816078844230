import React from "react";
import { Breadcrumb, Button, Navbar } from "react-bootstrap";
import { IoArrowBackOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from "react-router-dom";

const CreateMentorshipEventsHeader = ({ groupId, groupTitle }) => {
  return (
    <div>
      {" "}
      <Navbar expand="lg" className="p-4 bg-black">
        <div className="d-flex flex-column">
          <Navbar.Brand className="text-white py-3 create-event-title">
            Create Group Events
          </Navbar.Brand>
          <Breadcrumb>
            <Breadcrumb.Item
              className=" text-white text-decoration-none"
              as={Link}
              to={`/e-mentoring/group-details/${groupId}`}
              style={{ fontSize: "12px" }}
              active
            >
              {groupTitle}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              style={{ fontSize: "12px" }}
              active
              className="text-white"
            >
              Create Group Events
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Navbar.Toggle aria-controls="myNavbar2" className="navbar-toggler">
          <RxHamburgerMenu className="text-white fs-4" />
        </Navbar.Toggle>

        <Navbar.Collapse id="myNavbar2">
          <div className="d-flex ms-auto flex-wrap  justify-content-start">
            <Link to={`/e-mentoring/group-details/${groupId}`}>
              <Button variant="dark rounded-5" style={{ fontSize: "14px" }}>
                Go back to your group <IoArrowBackOutline />
              </Button>
            </Link>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default CreateMentorshipEventsHeader;
