import GroupDetailsHeader from "./components/GroupDetailsHeader";
import GroupDetailsBody from "./components/GroupDetailsBody";
import { useParams } from "react-router-dom";
import { useGetGroupByIDQuery } from "../../../../../services/mentoring-service";
import PageLoader from "../../../../components/PageLoader";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const GroupDetails = () => {
  const { id } = useParams();
  const groupid = Number(id);
  const { data: response, isLoading } = useGetGroupByIDQuery(groupid);
  const groupData = response?.data;

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <div>
      <GroupDetailsHeader />
      <GroupDetailsBody groupData={groupData} />
    </div>
  );
};

export default ProtectedRoutes(GroupDetails, {
  allowedRoles: [
    "Super Admin",
    "Admin",
    "User",
    "Counsellor",
    "Mentor",
    "Usher",
    "Facilitator",
    "Call Center Manager",
    "Coordinator",
  ],
});
