import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { attendanceRequest } from "../../../../../../../models/request/mentoring-request";
import {
  useCheckInAttendanceMutation,
  useGetAttendanceQuery,
} from "../../../../../../../services/mentoring-service";
import { ForwardIcon } from "../../../../../../assets";

const MentoringCheckIn = ({ eventId, groupData }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [firstName] = useState(sessionStorage.getItem("firstName"));
  const [lastName] = useState(sessionStorage.getItem("lastName"));
  const [phoneNumber] = useState(sessionStorage.getItem("phoneNumber"));
  const [attendance] = useState<attendanceRequest>({
    participantFirstName: firstName,
    participantLastName: lastName,
    participantPhoneNumber: phoneNumber,
    participantRequestConfirmationId: eventId,
  });

  const [checkInMutation] = useCheckInAttendanceMutation();

  const handleCheckIn = async (e) => {
    e.preventDefault();
    try {
      const response = await checkInMutation(attendance);
      const { code, message } = response["data"];
      if (code === 0) {
        toast.success(message);
        navigate(
          `/e-mentoring/view-group-events/${groupData?.id}/${groupData?.name}/${eventId}`
        );
      } else {
        toast.error(message || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error creating region:", error);
    }
  };

  const { data: attendanceResponse, isLoading: attendanceLoading } =
    useGetAttendanceQuery({
      participantPhoneNumber: phoneNumber,
      participantRequestConfirmationId: eventId,
    });

  const attendanceData = attendanceResponse?.data;

  return (
    <>
      {attendanceLoading ? (
        <p>Loading...</p>
      ) : attendanceData && attendanceData.length > 0 ? (
        <Button
          style={{ fontSize: "12px" }}
          variant="success"
          className="rounded-5 event-button"
        >
          Checked
        </Button>
      ) : (
        <>
          <Button
            onClick={handleShow}
            style={{ fontSize: "12px" }}
            variant="dark"
            className="rounded-5 event-button"
          >
            Check-In{" "}
            <img
              className="ms-1 d-inline-flex"
              src={ForwardIcon}
              alt=""
              width="12px"
            />
          </Button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: "16px" }}>
                Mentoring Event Check-In
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: "14px" }}>
              Are you sure you want to check-in this event?
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
              <Button
                style={{ fontSize: "14px" }}
                variant="success"
                className="rounded-5"
                onClick={handleCheckIn}
              >
                Check-In
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default MentoringCheckIn;
