import SwiperComponent from "../../../../events/eventMain/components/SwiperComponent";
import { Card, Col, Row, Image, Button } from "react-bootstrap";
import { useGetGroupEventsQuery } from "../../../../../../../services/mentoring-service";
import { CalenderIcon, Clock, GameConsole } from "../../../../../../assets";
import { CiVideoOn } from "react-icons/ci";
import PageLoader from "../../../../../../components/PageLoader";
import { Link } from "react-router-dom";
import MentoringCheckIn from "./MentoringCheckIn";
import { useState } from "react";

const GroupEvents = ({ groupData }) => {
  const [storedUserId] = useState(sessionStorage.getItem("userId"));
  const { data: response, isLoading } = useGetGroupEventsQuery({
    page_index: 0,
    page_size: 99999999,
    groupId: groupData?.id,
  });

  const groupEvents = response?.data;

  if (!groupEvents || groupEvents.length === 0) {
    return <h5 className="pt-2" style={{ fontSize: "14px" }}>No Event Groups</h5>;
  }

  if (isLoading) {
    return <PageLoader />; // Show loading spinner
  }

  const isMember = groupData?.members.some(
    (member) => member.userId.toString() === storedUserId
  );

  return (
    <Col className="pt-4">
      <SwiperComponent>
        {groupEvents?.map((item) => (
          <Card
            className="bg-white rounded-4 object-fit-cover d-flex flex-column"
            key={item.id}
            style={{ height: "250px", maxWidth: "468px" }}
          >
            <Row className="g-0 h-100">
              {item.image && (
                <Col className=" rounded-start-4">
                  <Image
                    alt="Event Image"
                    src={item.image}
                    className="img-fluid w-100 h-100 object-fit-cover rounded-start-4"
                  />
                </Col>
              )}
              <Col>
                <Card.Body style={{ maxWidth: "260px" }}>
                  <div className="d-flex justify-content-between">
                    <Card.Title className="event-card-title">
                      {item.title?.length > 22
                        ? `${item.title.substring(0, 22)}...`
                        : item.title}
                    </Card.Title>
                  </div>

                  <ul className="list-unstyled d-flex flex-column event-card-text my-1">
                    <li className="icon-link text-decoration-underline">
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CiVideoOn className="fs-6 me-2 " />
                        {item.link?.length > 22
                          ? `${item.link.substring(0, 22)}...`
                          : item.link}
                      </a>
                    </li>

                    <li className="icon-link">
                      <Image src={Clock} alt="" width={20} />
                      {item.startTime} - {item.endTime}
                    </li>

                    <li className="icon-link text-nowrap">
                      <Image src={CalenderIcon} alt="" width={20} />
                      {item.startDay} - {item.endDay}
                    </li>
                  </ul>

                  <div className="d-flex align-items-center">
                    <Image
                      alt=""
                      className="rounded-circle p-1 bg-light"
                      src={GameConsole}
                      width={24}
                    />
                    <hr className="hr w-100"></hr>
                  </div>

                  <Card.Text className="event-card-text text-small text-muted">
                    {item.description?.length > 50
                      ? `${item.description.substring(0, 50)}...`
                      : item.description || "No description"}
                  </Card.Text>

                  <div className="m-0 p-0 d-flex justify-content-between g-2">
                    <Link
                      to={`/e-mentoring/view-group-events/${groupData?.id}/${groupData?.name}/${item.id}`}
                    >
                      <Button
                        variant="dark rounded-5"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        View Event
                      </Button>
                    </Link>

                    {isMember && (
                      <MentoringCheckIn
                        groupData={groupData}
                        eventId={item.id}
                      />
                    )}
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        ))}
      </SwiperComponent>
    </Col>
  );
};

export default GroupEvents;
