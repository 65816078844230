import { CalenderIcon, Clock, GameConsole } from "../../../../../assets";
import { Card, Col, Container, Row, Image, Badge } from "react-bootstrap";
import { CiVideoOn } from "react-icons/ci";
import { useGetAttendanceQuery } from "../../../../../../services/mentoring-service";

const MentorshipEventBody = ({ groupEventData }) => {
  const roles = sessionStorage.getItem("roles");
  const allowedRoles = ["Admin", "Super Admin", "Call Center Manager"];
  const { data: response, isLoading } = useGetAttendanceQuery({
    page: 0,
    size: 99999999,
  });
  const attendanceData = response?.data;
  return (
    <Container>
      <Row className="my-4">
        <Col lg={12}>
          <Card className="bg-transparent border-0">
            <Row className="g-0 flex-wrap flex-column flex-md-row">
              {groupEventData?.image && (
                <Col>
                  <Image
                    alt="Event Image"
                    src={groupEventData?.image}
                    className="img-fluid   object-fit-cover rounded-3 "
                    style={{ width: "500px" }}
                  />
                </Col>
              )}
              <Col>
                <Card.Body>
                  <Card.Title className="full-details-event-title">
                    {groupEventData?.title}
                  </Card.Title>
                  <ul className="list-unstyled full-details-event-card-text mb-0 pt-3">
                    <li className="icon-link text-decoration-underline">
                      <a
                        href={groupEventData?.link || "NA"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CiVideoOn className="fs-6 me-2 " />
                        {groupEventData?.link || "NA"}
                      </a>
                    </li>

                    <li className="icon-link d-flex py-2 ">
                      <Image src={CalenderIcon} alt="" width={14} />
                      {groupEventData?.startDay} - {groupEventData?.endDay}
                    </li>
                    <li className="icon-link d-flex pb-2">
                      <Image src={Clock} alt="" width={14} />
                      {groupEventData?.startTime} - {groupEventData?.endTime}
                    </li>
                  </ul>

                  <div className="d-flex py-2 align-items-center">
                    <Image
                      alt=""
                      className="rounded-circle p-1 bg-light"
                      src={GameConsole}
                      width={24}
                    />
                    <hr className="hr w-100"></hr>
                  </div>
                  <Card.Text className="full-details-event-description">
                    {groupEventData?.description || "No description"}
                  </Card.Text>

                  <Card.Text className="full-details-event-description m-0">
                    <div className="d-flex align-items-center">
                      <h5 className="py-1 w-25 m-0 event-label">Attendance</h5>
                      <hr className="hr w-75" />
                    </div>
                    <Badge
                      pill
                      bg="light"
                      style={{ fontSize: "13px" }}
                      className="text-dark ms-auto fw-normal"
                    >
                      Total : {attendanceData?.length}
                    </Badge>
                    {allowedRoles && (
                      <>
                        {attendanceData?.map((item, index) => (
                          <Badge
                            pill
                            bg="light"
                            className="me-1 p-2 fw-normal text-dark"
                            key={index}
                            style={{ fontSize: "12px" }}
                          >
                            {item.participantFirstName}{" "}
                            {item.participantLastName}
                          </Badge>
                        ))}
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MentorshipEventBody;
