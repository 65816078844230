import React from "react";
import { Accordion, Row } from "react-bootstrap";

const GroupTopic = ({ groupTopic }) => {

  if (!groupTopic || groupTopic.length === 0) {
    return <h5 style={{ fontSize: "14px" }}>No topics</h5>;
  }
  return (
    <div className="mt-4">
      <Accordion defaultActiveKey="0">
        {groupTopic?.map((topic) => (
          <Accordion.Item
            key={topic.id}
            className="bg-transparent border-2"
            eventKey={topic.id.toString()}
          >
            <Accordion.Header className="">
              <Row>
                <h5 className="fs-6">{topic.name}</h5>
                <p
                  className="py-0 my-0 text-muted"
                  style={{ fontSize: "13px" }}
                >
                  {topic.discussionTime}
                </p>
              </Row>
            </Accordion.Header>
            <Accordion.Body className="fs-6">
              <p>{topic.description}</p>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default GroupTopic;
