import React from "react";
import CreateMentorshipEventsHeader from "./components/CreateMentorshipEventsHeader";
import CreateMentorshipEventsBody from "./components/CreateMentorshipEventsBody";
import { useParams } from "react-router-dom";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";

const CreateMentorshipEvents = () => {
  const { groupId, groupTitle } = useParams();
  return (
    <div>
      <CreateMentorshipEventsHeader groupId={groupId} groupTitle={groupTitle} />
      <CreateMentorshipEventsBody groupId={groupId} />
    </div>
  );
};

export default ProtectedRoutes(CreateMentorshipEvents, {
  allowedRoles: ["Super Admin", "Admin", "Call Center Manager"],
});
