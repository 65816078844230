import React from "react";
import ProtectedRoutes from "../../../auth/utils/ProtectedRoutes";
import { useParams } from "react-router-dom";
import EditMentorshipEventsHeader from "./components/EditMentorshipEventsHeader";
import EditMentorshipEventsBody from "./components/EditMentorshipEventsBody";

const EditMentorshipEvents = () => {
  const { groupId, groupTitle, eventId } = useParams();
  return (
    <div>
      <EditMentorshipEventsHeader groupId={groupId} groupTitle={groupTitle} />
      <EditMentorshipEventsBody
        groupId={groupId}
        groupTitle={groupTitle}
        eventId={eventId}
      />
    </div>
  );
};

export default ProtectedRoutes(EditMentorshipEvents, {
  allowedRoles: ["Super Admin", "Admin", "Call Center Manager"],
});
