import React from "react";
import { CSVLink } from "react-csv";
import { CiExport } from "react-icons/ci";

const ExportData = ({ exportfilteredData, resetPage }) => {
  // Function to format the data as neatly arranged strings
  const formatData = (data) => {
    // If `data` is not an array, convert it to an array or return an empty array
    if (!Array.isArray(data)) {
      console.error("Expected array but received:", data);
      return []; // Return an empty array to avoid runtime errors
    }

    // Map over the array to format data
    return data.map((user) => ({
      ID: user.id?.toString() || "",
      FirstName: user.firstName || "",
      MiddleName: user.middleName || "",
      LastName: user.lastName || "",
      Email: user.email || "",
      PhoneNumber: user.phone || "",
      WhatsappNumber: user.whatsappNumber || "",
      Age: user.age || "",
      Gender: user.gender || "",
      HighestLevelOfEducation: user.highestLevelOfEducation || "",
      SpringboardCategory: user.springboardCategory || "",
      DisabilityStatus: user.disabilityStatus || "",
      RegistrationChannel: user.registrationChannel || "",
      CreatedOn: user.createdOn
        ? new Date(user.createdOn).toLocaleDateString()
        : "",
      UpdateOn: user.updateOn
        ? new Date(user.updateOn).toLocaleDateString()
        : "",
    }));
  };

  const dataToExport = formatData(exportfilteredData || []);
  const headers = Object.keys(dataToExport[0] || {});
  const csvData = dataToExport.map((user) => Object.values(user));
  csvData.unshift(headers.map((header) => header.toString()));

  const handleExportClick = () => {
    // Reset the page index when export link is clicked
    resetPage();
  };

  return (
    <div>
      <CSVLink
        data={csvData}
        filename="users.csv"
        className="btn rounded-5 mx-1"
        target="_blank"
        style={{
          fontSize: "12px",
          background: "rgba(25, 22, 29, 0.06)",
        }}
        onClick={handleExportClick}
      >
        Export <CiExport />
      </CSVLink>
    </div>
  );
};

export default ExportData;
